import { createTheme } from "@mui/material";
import { COLORS } from "./consts";
import { interpolateColor } from "./commonFunctions";

export const MUI_GRAY = interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.6)
const GRANIITTI_HOVER = interpolateColor(COLORS.sitowise.musta, COLORS.sitowise.graniitti, 0.18);

export const MUI_THEME_LEFT_PANEL = createTheme({
  typography: {
    fontFamily: "Outfit",
    fontSize: 11.5,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          borderRadius: 16,
          textTransform: "none",
          width: "100%",
          height: 44,
          color: COLORS.sitowise.graniitti,
          borderColor: COLORS.sitowise.graniitti,
          '&:hover': {
            backgroundColor: GRANIITTI_HOVER
          },
        }
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          textTransform: "none",
          margin: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: MUI_GRAY,
          padding: 0,
          "&.Mui-checked": {
            color: COLORS.sitowise.graniitti
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          p: 50,
          color: COLORS.sitowise.graniitti
        },
        markLabel: {
          fontSize: 12,
          color: MUI_GRAY
        }
      }
    },
  },
});


export const MUI_THEME_AREA_INFO = createTheme({
  typography: {
    fontFamily: "Outfit",
    body1: {
      fontSize: 13,
      lineHeight: 1.2
    }
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          color: "#ffffff",
          backgroundColor: COLORS.sitowise.musta,
          borderRadius: 18,
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "#175378",

          boxShadow: `0 5px 20px rgba(0, 0, 0, 0.8)`
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15), // Hover color when not selected
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit",
          //fontWeight: "bold",
          fontSize: 14,
          padding: "0px",
          color: "#ffffff",
          flex: 1,
          justifyContent: "flex-start",
          textAlign: "left"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // marginTop: 14,
          // marginBottom: 14,
          fontSize: 13,
          lineHeight: 1.1,
          borderRadius: 10,
          textTransform: "none",
          textAlign: "left",
          justifyContent: "flex-start",
          minHeight: 34,
          color: COLORS.sitowise.graniitti,
          borderColor: COLORS.sitowise.graniitti,
          '&:hover': {
            backgroundColor: GRANIITTI_HOVER
          },
          "& .MuiSvgIcon-root": {
            color: COLORS.sitowise.graniitti,
          }
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: COLORS.sitowise.graniitti,
          }
        }
      }
    },
  },
});


export const MUI_THEME_BUILDING_INFO = createTheme({
  typography: {
    fontFamily: "Outfit"
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: "Outfit",
          //fontWeight: "bold",
          fontSize: 14,
          padding: "0px",
          color: "#ffffff"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          borderRadius: 16,
          textTransform: "none",
          width: "100%",
          height: 44,
          color: COLORS.sitowise.graniitti,
          borderColor: COLORS.sitowise.graniitti,
          "&:hover": {
            backgroundColor: GRANIITTI_HOVER
          },
          '&.Mui-disabled': {
            color: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.6),
            borderColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.6)
          }
        },
      },
    },
  },
});


export const MUI_THEME_LOGIN = createTheme({
  typography: {
    fontFamily: "Outfit",
    body1: {
      fontSize: 16,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          borderRadius: 12,
          textTransform: "none",
          //backgroundColor: COLORS.eluokat.D,
          height: 45,
          backgroundColor: COLORS.sitowise.kajo,
          color: "#000000"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#ffffff"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#a0a0a0",
          "&.Mui-focused": {
            color: COLORS.sitowise.kajo,
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a0a0a0", // default border color
            borderWidth: 1
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.sitowise.kajo, // hover border color
            borderWidth: 1
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a0a0a0", // focused border color
            borderWidth: 1
          },
          borderRadius: 12,
        }
      }
    }
  },
});


export const MUI_THEME_ENERGY_CALCULATOR = createTheme({
  typography: {
    fontFamily: "Outfit",
    fontSize: 13
  },
  components: {
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#3a3a3a",
    //       color: "#333",
    //     },
    //   },
    // },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: MUI_GRAY,
          "&.Mui-checked": {
            color: "#ffffff",
          },
          '&.MuiCheckbox-indeterminate': {
            color: '#ffffff',
          },
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.musta_black_combination,
          "&:hover": {
            backgroundColor: interpolateColor(COLORS.sitowise.musta, COLORS.sitowise.kajo, 0.2)
          },
          "&:hover MuiTableCell-root": {
            color: "#000000"
          },
          "&.Mui-selected": {
            backgroundColor: interpolateColor(COLORS.sitowise.musta, COLORS.sitowise.kajo, 0.2)
          },
          "&.Mui-selected:hover": {
            // Hover and selected
            backgroundColor: interpolateColor(COLORS.sitowise.musta, COLORS.sitowise.kajo, 0.5)
          }
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          borderColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15)
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          "&:hover": {
            color: "#a6a6a6"
          },
          "&.Mui-active": {
            color: "#ffffff"
          },
          "&.Mui-active:hover": {
            color: "#a6a6a6" // Hover and active
          },
          "& .MuiTableSortLabel-icon": {
            color: "#a6a6a6"
          },
          "&.Mui-active .MuiTableSortLabel-icon": {
            color: "#ffffff"
          },
          "&.Mui-active:hover .MuiTableSortLabel-icon": {
            color: "#a6a6a6" // Hover and active
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#ffffff"
          },
          color: "#8a8a8a"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          width: 150
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#a0a0a0",
          "&.Mui-focused": {
            color: COLORS.sitowise.kajo,
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a0a0a0", // default border color
            borderWidth: 1
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.sitowise.kajo, // hover border color
            borderWidth: 1
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a0a0a0", // focused border color
            borderWidth: 1
          },
          borderRadius: 12,
        },
      },
    }
  },
});


export const MUI_THEME_SEARCH_BOX = createTheme({
  typography: {
    fontFamily: "Outfit",
    fontSize: 12
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          backgroundColor: COLORS.sitowise.musta
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15),
            color: 'white',
            '&:hover': {
              backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15)
            },
          },
          '&:hover': {
            backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.15),
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 45,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a0a0a0", // default border color
            borderWidth: 1
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.sitowise.kajo, // hover border color
            borderWidth: 1
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.sitowise.kajo, // focused border color
            borderWidth: 1
          },
          borderRadius: 12
        }
      }
    }
  }
})