import { useContext, useEffect, useRef, useState } from "react";
import { Box, IconButton, Tab, Tabs, ThemeProvider } from "@mui/material"

import { EnergyCalculatorContext, SelectedBuildingContext, SetContext, } from "../Misc/Context";
import { ActionsTab } from "./ActionsTab";
import { ParametersTab } from "./ParametersTab";
import "./EnergyCalculator.css"
import { MUI_THEME_ENERGY_CALCULATOR } from "../Misc/muiThemes";
import { COLORS, SERVER_URL } from "../Misc/consts";
import { axiosPost, interpolateColor } from "../Misc/commonFunctions";
import Draggable from "react-draggable";
import { TInfoArr } from "dippa-shared";
import { ArrowForward, Close } from "@mui/icons-material";
import { InfoArr } from "../Misc/InfoArr";


const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CalculationResults = ({ title, results }: { title: string, results: TInfoArr }) => {
  // @ts-ignore
  const color = COLORS.eluokat[results[0].value]
  return (
    <div style={{ justifyContent: "flex-end", display: "flex", flexDirection: "column" }}>
      <p style={{ width: 200, margin: "0 auto", textAlign: "center" }}>
        {title}
      </p>
      <div style={{
        marginTop: 10,
        borderColor: color,
        borderStyle: "solid",
        borderWidth: 4,
        borderRadius: 20,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 12,
        paddingBottom: 12,
      }}>
        <InfoArr arr={results} />
      </div>
    </div>
  )
}


export const EnergyCalculator = () => {
  const { selectedBuilding } = useContext(SelectedBuildingContext);
  const { energyCalculatorOpen, selectedActions, energyCalcProps, energyCalcResponse } = useContext(EnergyCalculatorContext);
  const { setEnergyCalcResponse, setEnergyCalculatorOpen, setEnergyCalcProps, setSelectedActions } = useContext(SetContext);
  const [tabIndex, setTabIndex] = useState(0);
  const rtunnus = useRef<string>()

  const fetchData = async () => {
    try {
      const response = await axiosPost(`${SERVER_URL}/energy-calculator.json`, {
        rtunnus: rtunnus.current,
        calcProps: energyCalcProps,
        selectedActions: selectedActions
      });
      setEnergyCalcResponse(response.data);
    }
    catch {
      // TODO
    }
  }


  useEffect(() => {
    if (!selectedBuilding?.properties?.rtunnus) return;
    setEnergyCalcProps(undefined);
    setSelectedActions([]);
    rtunnus.current = selectedBuilding.properties.rtunnus
  }, [selectedBuilding])


  useEffect(() => {
    if (!energyCalculatorOpen) {
      setTabIndex(0);
      return;
    }
    fetchData();
  }, [energyCalculatorOpen, selectedActions, energyCalcProps])


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };


  if (!energyCalculatorOpen) return null;

  return (
    <Draggable
      bounds="parent"
      handle=".draggable-handle"
    >
      <div className="energy-calculator">
        <ThemeProvider theme={MUI_THEME_ENERGY_CALCULATOR}>
          <Box
            className="draggable-handle"
            sx={{
              cursor: 'grab',
              borderBottom: 1,
              borderColor: 'divider',
            }}>
            <Tabs value={tabIndex} onChange={handleChange}>
              <Tab label="Toimenpiteet" {...a11yProps(0)} />
              <Tab label="Laskentaparametrit" {...a11yProps(1)} />
            </Tabs>
          </Box>

          {tabIndex === 0 ? (
            <ActionsTab />
          ) : null}
          {tabIndex === 1 ? (
            <ParametersTab />
          ) : null}

          <IconButton
            onClick={() => {
              setEnergyCalculatorOpen(false);
            }}
            sx={{ position: "absolute", right: 2, top: 2 }}
          >
            <Close sx={{ color: "#b8b8b8", fontSize: 30, padding: 0 }} />
          </IconButton>

          {energyCalcResponse?.actionsEffect ? (
            <div style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              marginTop: 22,
              marginBottom: 26,
              marginLeft: 26,
              marginRight: 26
            }}>
              <CalculationResults
                title={energyCalcResponse.originalResults.title}
                results={energyCalcResponse.originalResults.results}
              />
              <ArrowForward style={{ alignSelf: "center", margin: 20 }} />
              <CalculationResults
                title={energyCalcResponse.currentResults.title}
                results={energyCalcResponse.currentResults.results}
              />
              {energyCalcResponse.scenarioResults ? (
                <>
                  <ArrowForward style={{ alignSelf: "center", margin: 20 }} />
                  <CalculationResults
                    title={energyCalcResponse.scenarioResults.title}
                    results={energyCalcResponse.scenarioResults.results}
                  />
                </>
              ) : null}
            </div>
          ) : null}
        </ThemeProvider>
      </div>
    </Draggable>
  )
}